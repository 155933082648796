import React from 'react';

import ReactAudioPlayer from 'react-audio-player';

import styled from 'styled-components';

import { Heading, Box } from 'grommet';
import { GatsbyImage } from 'gatsby-plugin-image';
import TagBox from './tagBox';
import Block from './block';

const StyledRadio = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-content: center;

    .gatsby-image-wrapper {
        width: 200px;
        min-width: 200px;
        height: auto;
    }
`;
const radio = ({ audio, heading, intro, image }) => (
    <Box className="light-1">
        <Block>
            <TagBox flex="grow">
                <StyledRadio>
                    <Heading level={2}>{heading}</Heading>
                    {intro && (
                        <Heading level="3" margin="none" textAlign="center">
                            {intro}
                        </Heading>
                    )}
                    <Box
                        direction="row-responsive"
                        gap="medium"
                        align="center"
                        justify="center"
                        alignContent="center"
                        alignSelf="center"
                    >
                        {image && (
                            <GatsbyImage
                                image={image.localFile.childImageSharp.gatsbyImageData}
                                alt={image.altText}
                                loading="eager"
                            />
                        )}
                       {audio?.localFile?.publicURL && <ReactAudioPlayer src={audio.localFile.publicURL} controls />}
                    </Box>
                </StyledRadio>
            </TagBox>
        </Block>
    </Box>
);

export default radio;
